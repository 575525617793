const OBJECT_VAL= [];

OBJECT_VAL["1"]= "ethereum";
 OBJECT_VAL["42161"]= "arbitrum";
  OBJECT_VAL["5"]= "goerli";
  OBJECT_VAL["137"]= "polygon";
  OBJECT_VAL["80001"]= "mumbai";
  OBJECT_VAL["56"]= "bsc";
  OBJECT_VAL["7777777"]= "zora";
  OBJECT_VAL["420"]= "optimism";
  OBJECT_VAL["10"]= "optimism";
OBJECT_VAL["11155111"]= "sepolia";
OBJECT_VAL["8453"]= "base";
OBJECT_VAL["43114"]= "avalanche";
OBJECT_VAL["42170"]= "arbitrum-nova";


export default OBJECT_VAL