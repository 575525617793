const OBJECT_VAL= [];

OBJECT_VAL["1"]= "https://api.reservoir.tools/collections/trending-mints/v1";
 OBJECT_VAL["42161"]= "https://api-arbitrum.reservoir.tools/collections/trending-mints/v1";
  OBJECT_VAL["5"]= "https://api-goerli.reservoir.tools/collections/trending-mints/v1";
  OBJECT_VAL["137"]= "https://api-polygon.reservoir.tools/collections/trending-mints/v1";
  OBJECT_VAL["80001"]= "https://api-mumbai.reservoir.tools/collections/trending-mints/v1";
  OBJECT_VAL["56"]= "https://api-bsc.reservoir.tools/collections/trending-mints/v1";
  OBJECT_VAL["7777777"]= "https://api-zora.reservoir.tools/collections/trending-mints/v1";
  OBJECT_VAL["420"]= "https://api-optimism.reservoir.tools/collections/trending-mints/v1";
   OBJECT_VAL["10"]= "https://api-optimism.reservoir.tools/collections/trending-mints/v1";
   OBJECT_VAL["8453"]= "https://api-optimism.reservoir.tools/collections/trending-mints/v1";


export default OBJECT_VAL