const OBJECT_VAL= [];

OBJECT_VAL["1"]= " Ethereum";
 OBJECT_VAL["42161"]= " Arbitrum One";
  OBJECT_VAL["5"]= " Goerli";
  OBJECT_VAL["80001"]= " Mumbai";
  OBJECT_VAL["56"]= " BNB Smart Chain";
  OBJECT_VAL["137"]= " Polygon";
  OBJECT_VAL["7777777"]= " Zora";
  OBJECT_VAL["420"]= " Optimism";
   OBJECT_VAL["10"]= " Optimism";
  OBJECT_VAL["11155111"]= " Sepolia";
  OBJECT_VAL["8453"]= " Base";
  OBJECT_VAL["43114"]= " Avalanche";
OBJECT_VAL["42170"]= " Nova Arbitrum";

export default OBJECT_VAL