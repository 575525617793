const OBJECT_VAL= [];

OBJECT_VAL["1"]= "512.png";
 OBJECT_VAL["42161"]= "arbitrum.png";
  OBJECT_VAL["5"]= "512.png";
  OBJECT_VAL["80001"]= "polygon.png";
  OBJECT_VAL["56"]= "bnb.png";
  OBJECT_VAL["137"]= "polygon.png";
  OBJECT_VAL["7777777"]= "CoreZorb.svg";
  OBJECT_VAL["420"]= "optimism.svg";
   OBJECT_VAL["10"]= "optimism.svg";
  OBJECT_VAL["11155111"]= "sapolia.png";
  OBJECT_VAL["8453"]= "base.svg";
    OBJECT_VAL["43114"]= "icons/avalanche-icon-dark.svg";
OBJECT_VAL["42170"]= "icons/arbitrum-nova-icon-dark.svg";


export default OBJECT_VAL